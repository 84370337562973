.panel-horizontal {
    background-color: white;
    position: sticky;
    top: 0;
}

.template-combobox {
    flex: 1;
}

.panel-export-template {
    border: 0.5px solid #dddddd;
    border-radius: 10px;
    padding: 0;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 20px;
}

.column-designer {
    height: 70vh;
    overflow: auto;
}

.form-row-column-designer {
    padding: 0;
    flex: 1;
    justify-content: flex-start;
    align-items: stretch;
}

.form-row-column-designer > div {
    flex: 1;
    overflow: auto;
    margin: 0;
}

.form-row-column-designer > form {
    flex: 1;
    overflow: auto;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
}

.form-row-column-designer > form > .form-row:nth-child(3) {
    flex: 1;
}

.form-row-column-designer > div:first-child {
    border-right: 0.5px solid #dddddd;
}

.column-designer-button-container {
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    border-bottom: 0.5px solid #dddddd;
}

.column-designer-button-container > * {
    margin: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.column-detail-title {
    padding-top: 12px;
    padding-bottom: 12.5px;
}

.export-designer-panel {
    border-top: 0.5px solid #dddddd;
}

.export-designer-button-container {
    justify-content: space-between;
    position: sticky;
    top: 0;
}

.listing-row-col-def {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.listing-row-col-def > div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.listing-row-col-def > div:first-child > *:first-child {
    color: #00b1b6;
    margin-bottom: 10px;
}

.export-format-textarea textarea {
    height: 40vh;
}