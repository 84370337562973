.clock-data-table {
    height: 74vh;
    position: relative;
}

.clock-data-detail-panel {
    top: 19vh;
    right: 5vw;
    position: fixed;
    width: 27vw;
    height: 78vh;
    border: 0.5px solid #dddddd;
    border-radius: 10px;
    overflow: auto;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    background-color: white;
    display: none;
    z-index: 4;
}

.clock-data-detail-panel > textarea {
    height: 30px;
}

@keyframes clock-data-detail-panel-shown {
    from { right: -100vw }
    to { right: 5vw  }
}

.clock-data-detail-panel-show {
    display: block;
    animation-name: clock-data-detail-panel-shown;
    animation-duration: 0.5s;
}

.detail-panel-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.detail-panel-title > button {
    background-color: transparent;
    border: 0;
}

.map {
    width: 100%;
    height: 342px;
}

.checkboxcollection-export {
    height: 30vh;
    margin-top: 10px;
}

@media (min-width: 1920px) {
    .clock-data-detail-panel {
        height: 78vh;
    }

    .clock-data-table {
        height: 78vh;
    }
}

@media (max-width: 1280px) {
    .clock-data-detail-panel {
        width: 30vw;
    }
}