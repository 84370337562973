.action-button {
    border: 0;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background-color: #00b1b6;
    cursor: pointer;
}

.button {
    width: auto;
    height: auto;
    background-color: #ccfdff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;
    padding-right: 10px;
    padding-left: 10px;
    border: 0;
    border-radius: 15px;
    cursor: pointer;
}

.button:hover {
    background-color: #b3fcff;
}

.button-label {
    color: #00b1b6;
    font-weight: bold;
}

.button-label-margin {
    margin-left: 10px;
}

.input-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    margin-bottom: 15px;
}

.horizontal-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 0;
}

.horizontal .input {
    flex: 1;
}

.horizontal label {
    margin-right: 20px;
}

/* label {
    font-weight: bold;
} */

.input-text {
    border: 1px solid #dddddd;
    border-radius: 5px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-top: 5px;
    background-color: #fdfdfd;
}

.input-text-readonly {
    font-weight: bold;
}

.required-label {
    font-size: small;
    font-style: italic;
    color: #3d3d3d;
    margin: 5px;
}

.combobox {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    margin-bottom: 15px;
}

.combobox-horizontal {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 0;
}

.combobox-horizontal>select {
    flex: 1;
}

.combobox-horizontal>label {
    margin-right: 20px;
}

/* .combobox>label {
    font-weight: bold;
} */

.combobox>select {
    border: 1px solid #dddddd;
    border-radius: 5px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-top: 5px;
}

.checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
    margin-bottom: 10px;
}

.checkbox input {
    font-size: medium;
    margin-right: 10px;
}

.checkbox > div {
    margin-right: 7px;
    cursor: pointer;
}

/* .checkbox label {
    font-weight: bold;
} */

.dateinput {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    margin-bottom: 15px;
}

/* .dateinput>label {
    font-weight: bold;
} */

.checkbox-collection {
    width: 100%;
    border: 0.5px solid #dddddd;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
}

.checkbox-collection-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 0.5px solid #dddddd;
    padding-left: 10px;
}

.checkbox-collection-item>span {
    margin-left: 20px;
}

.checkbox-collection-item:last-child {
    border-bottom: 0;
}

.slider-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: auto;
    margin-bottom: 15px;
}

.slider-label {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}

.slider-label>strong {
    margin-bottom: 5px;
}

.slider-container > input {
    background-color: white;
    border: 0;
    width: 100%;
}

.datetimeinput-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    margin-bottom: 15px;
}

.datetimeinput-container > .input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
}

.input {
    border: 0;
    border-bottom: 1px solid #dddddd;
    padding-top: 10px;
    padding-bottom: 10px;
}

.searchbox-collection {
    top: 0;
    position: sticky;
    border: 0;
    border-bottom: 0.5px solid #dddddd;
    padding: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}