nav {
    width: 100%;
    height: 7vh;
    top: 0;
    left: 0;
    position: sticky;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 5;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}

nav > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
}

nav > div:first-child {
    justify-content: flex-start;
    padding-left: 1.55vw;
}

nav > div:last-child {
    justify-content: flex-end;
    padding-right: 20px;
}

.nav-button {
    background-color: white;
    border: 0.5px solid #dddddd;
    border-radius: 5px;
    cursor: pointer;
}

.nav-button:hover {
    background-color: #f2f2f2;
}

.nav-button > img {
    height: 24px;
}

.nav-logo {
    height: 38px;
    margin-right: 1.4vw;
}

.nav-link {
    width: 18vw;
    border: 0.5px solid white;
    background-color: white;
    color: black;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.nav-link:hover {
    background-color: #f0f0f0;
    border: 0.5px solid #dddddd;
}

.nav-link-active {
    color: #00a3a8;
}

.nav-link > img {
    height: 24px;
    margin-right: 2vw;
    margin-left: 2vw;
}

.nav-menu {
    width: 5.5vw;
    height: 93vh;
    background-color: white;
    border: 0;
    top: 7vh;
    left: 0;
    position: fixed;
    align-items: flex-start;
    overflow: hidden;
    z-index: 4;
    box-shadow: 3px 0px 5px rgba(0, 0, 0, 0.1);
}

.nav-menu > strong, .nav-menu > .nav-link > span {
    display: none;
    opacity: 0;
}

.nav-menu:hover, .nav-menu-pin {
    width: 20vw;
    padding-left: 2vw;
    animation-name: nav-menu-shown;
    animation-duration: 1s;
}

.nav-menu:hover > strong, 
.nav-menu-pin > strong,
.nav-menu:hover > .nav-link > span, 
.nav-menu-pin > .nav-link > span 
{
    display: block;
    opacity: 1;
    animation-name: nav-menu-shown-items;
    animation-duration: 1s;
}

@keyframes nav-menu-shown {
    from { 
        width: 5.5vw;
    }
    to { 
        width: 20vw;
    }
}

@keyframes nav-menu-shown-items {
    from { 
        opacity: 0;
    }
    to { 
        opacity: 1;
    }
}

.nav-menu > * {
    color: black;
}

.nav-menu > strong {
    margin-top: 2vh;
}

.nav-menu > *:last-child {
    margin-bottom: 0;
}

.nav-menu > button:first-child {
    background-color: white;
    border: 0;
    cursor: pointer;
    margin-top: 1vh;
    align-self: flex-end;
}

.nav-account-button {
    border: 0;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.nav-account-button > img {
    height: 24px;
    margin-right: 10px;
}

.nav-logout-button {
    height: 7vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 0;
    background-color: white;
    cursor: pointer;
}

.nav-logout-button > img {
    height: 24px;
    margin-left: 20px;
}

.nav-manual-button {
    height: 7vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 0;
    background-color: white;
    cursor: pointer;
}

.nav-manual-button > img {
    height: 24px;
    margin-left: 20px;
}

.nav-company-name {
    margin-left: 20px;
    font-size: smaller;
    cursor: pointer;
    text-decoration: false;
}

.nav-company-name:visited {
    color: black;
}

.message-bg {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 10;
}

.message-bg-show {
    display: flex;
}

.message-box {
    width: 30%;
    background-color: white;
    border: 0.5px solid #dddddd;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    overflow: hidden;
    opacity: 0;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

@keyframes message-box-shown {
    from { opacity: 0 }
    to { opacity: 1 }
}

.message-box-show {
    opacity: 1;
    animation-name: message-box-shown;
    animation-duration: 0.5s;
}

.message-box-header {
    background-color: #00b1b6;
    padding-right: 10px;
    padding-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: white;
}

.message-box-header > button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
}

.message-box-header > button > img {
    height: 32px;
}

.message-box-body {
    background-color: white;
    overflow-wrap: break-word;
    text-align: center;
    padding: 10px;
}

.message-box-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.message-box-footer > button {
    margin-right: 5px;
    margin-left: 5px;
}

.page-container {
    height: 90vh;
    padding-right: 2vw;
    padding-left: 7vw;
    z-index: 1;
    overflow: auto;
    margin-top: 2vh;
}

.panel {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 20px;
    background-color: white;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    overflow: auto;
}

.panel-horizontal {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    background-color: white;
    z-index: 3;
}

.panel-inner {
    display: flex;
    flex-direction: row;
    flex: 1;
}

.panel-inner-start {
    justify-content: flex-start;
    align-items: center;
}

.panel-inner-start > * {
    margin-right: 10px;
}

.panel-inner-end {
    justify-content: flex-end;
    align-items: center;
}

.panel-inner-end > * {
    margin-left: 10px;
}

.dialog-bg {
    width: 94.5vw;
    height: 93vh;
    top: 7vh;
    left: 5.5vw;
    position: fixed;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 3;
    overflow: auto;
}

.dialog-bg-show {
    display: flex;
}

@keyframes dialog-shown {
    from { opacity: 0 }
    to { opacity: 1 }
}

.dialog {
    width: 50vw;
    background-color: white;
    border: 0.5px solid #dddddd;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    overflow: hidden;
    animation-name: dialog-shown;
    animation-duration: 0.3s;
    margin-top: 5vh;
    margin-bottom: 5vh;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.dialog-header {
    padding: 10px;
    padding-right: 20px;
    padding-left: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.5px solid #dddddd;
}

.dialog-header > span {
    font-weight: bold;
}

.dialog-header > button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
}

.dialog-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    overflow: auto;
}

.dialog-body-free {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    overflow: auto;
    padding-right: 20px;
    padding-left: 20px;
}

.dialog-body > pre {
    margin: 10px;
}

.form-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px;
    padding-right: 20px;
    padding-left: 20px;
}

.form-row > * {
    flex: 1;
    margin-right: 20px;
    margin-left: 20px;
}

.form-row > *:first-child {
    margin-left: 0;
}

.form-row > *:last-child {
    margin-right: 0;
}

.form-row-border-top {
    border-top: 0.5px solid #dddddd;
}

.form-bottom {
    background-color: white;
    bottom: 0;
    position: sticky;
}

.form-section {
    padding: 10px;
    padding-right: 20px;
    padding-left: 20px;
    background-color: #00b1b6;
    color: white;
    top: 0;
    position: sticky;
}

.form-title {
    background-color: white;
    padding: 10px;
    padding-right: 20px;
    padding-left: 20px;
    border-bottom: 0.5px solid #dddddd;
}

a {
    text-decoration: none;
}

.side-panel {
    float: right;
    width: 57vw;
    border: 0.5px solid #dddddd;
    border-radius: 10px;
    margin-left: 1vw;
    overflow: auto;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.listing {
    width: 30vw;
    height: 74vh;
    position: relative;
    border: 0.5px solid #dddddd;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    overflow: auto;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.listing-search-box {
    padding: 10px;
    border: 0;
    border-bottom: 0.5px solid #dddddd;
    top: 0;
    position: sticky;
    border-radius: 0;
}

.listing-row {
    border-bottom: 0.5px solid #dddddd;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
    background-color: white;
}

@keyframes listing-row-hover {
    from { background-color: white }
    to { background-color: #f0f0f0 }
}

.listing-row:hover {
    background-color: #f0f0f0;
    animation-name: listing-row-header;
    animation-duration: 0.5s;
}

.listing-row > * {
    margin-bottom: 10px;
}

.listing-row > *:last-child {
    margin-bottom: 0;
}

.listing-row > strong:first-child {
    color: #00b1b6;
}

.title {
    font-size: larger;
}

.tab-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-bottom: 0.5px solid #dddddd;
    padding: 10px;
    padding-bottom: 0;
}

.tab-container > button {
    background-color: #ccfdff;
    color: #00b1b6;
    font-weight: bold;
    border: 0;
    border-bottom: 0;
    border-radius: 10px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    padding: 10px;
    cursor: pointer;
    margin-right: 10px;
}

.tab-container > button:hover {
    background-color: #b3fcff;
}

.loading-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.loading-container {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 9;
}

.loading-container > span {
    font-size: 1em;
    margin-right: 20px;
}

@keyframes loading-running {
    0% { background-image: url("hourglass_top_black.svg") }
    20% { transform: rotate(180deg) }
    30% { background-image: url("hourglass_bottom_black.svg") }
    60% { transform: rotate(180deg) }
    70% { background-image: url("hourglass_top_black.svg") }
    100% { transform: rotate(180deg) }
}

.loading-container > div {
    background-image: url("hourglass_top_black.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 40px;
    height: 40px;
    animation-name: loading-running;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.diagram {
    width: 57vw;
}

@media (min-width: 1920px) {
    .page-container {
        padding-right: 2vw;
        padding-left: 7vw;
    }

    .side-panel {
        width: 53vw;
    }

    .listing {
        height: 78vh;
    }
}